<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @cancel="handleModalCancel"
        width="60%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- program -->
            <AFormItem
                label="Pilih Program"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="program"
                has-feedback>
                <FilterProgram
                    style="width:100%"
                    disabled
                    :mode="null"
                    v-model:value="form.program"/>
            </AFormItem>

            <!-- toko -->
            <AFormItem
                label="Pilih Toko"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="toko"
                has-feedback>
                <FilterToko
                    style="width:100%"
                    :disabled="form.readOnly"
                    v-model:value="form.toko"/>
            </AFormItem>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import FilterProgram from '@/components/filter/FilterProgram'
import FilterToko from '@/components/filter/FilterToko'
import { Modal, message } from 'ant-design-vue'
import {
    ROLE_ADMIN_BK,
    hasRoles,
} from '@/helpers'
import { toInteger } from 'lodash'

export default defineComponent({
    components: {
        FilterProgram,
        FilterToko,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            program: [],
            toko: [],
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Program Toko',
            endpoint: {
                create: '/api/loyalty-program-stores',
            },
            loading: false,
            rules: {
                program: [
                    {
                        required: true,
                        message: 'Program tidak boleh kosong!',
                    },
                ],
                toko: [
                    {
                        required: true,
                        message: 'Toko tidak boleh kosong!',
                    },
                ],
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            form.post(state.endpoint.create)
                .then(({ data }) => {
                    message.success('Berhasil menyimpan')

                    handleModalCancel()
                    emit('success', data)
                })
                .catch(({ response: { data, status } }) => {
                    state.errors = data
                })

        }

        // handle func vue
        onMounted(() => {
            form.reset()

            form.program = toInteger(props.item.program)

            if (!form.id) {
            } else {
                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Program Toko`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
